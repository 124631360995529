import { Box } from "@mui/material";

export default function TerminosProfesionales() {
    return (
        <Box>
            <p class="p1"><strong>T&eacute;rminos y Condiciones de Uso para Profesionales en FIXER</strong></p>
            <p class="p2"><strong>&Uacute;ltima Actualizaci&oacute;n</strong>: 25/09/2024</p>
            <p class="p3">Estos <strong>T&eacute;rminos y Condiciones</strong> (en adelante, los "T&eacute;rminos") regulan el acceso y uso de la plataforma <strong>FIXER</strong> (en adelante, la "Plataforma") por parte de las personas que ofrecen sus servicios a trav&eacute;s de la misma (en adelante, el "Profesional" o los "Profesionales"). A efectos de estos T&eacute;rminos, el t&eacute;rmino "Profesional" incluir&aacute; tanto a los profesionales con formaci&oacute;n acad&eacute;mica, como a aquellas personas que realicen oficios, servicios t&eacute;cnicos u otras actividades calificadas, que deseen ofrecer sus servicios a trav&eacute;s de la Plataforma. Al registrarse en la Plataforma y ofrecer sus servicios, el Profesional acepta plenamente estos T&eacute;rminos.</p>
            <p class="p3"><strong>1. Naturaleza del Servicio</strong>: FIXER es una plataforma digital que facilita el contacto entre Profesionales y usuarios finales (en adelante, los &ldquo;Usuarios&rdquo;). FIXER no participa ni es parte en las relaciones contractuales que se generan entre los Profesionales y los Usuarios, ni en la ejecuci&oacute;n de los servicios ofrecidos. En consecuencia, FIXER no asume responsabilidad alguna por la calidad, seguridad, idoneidad o cumplimiento de los servicios prestados por los Profesionales.</p>
            <p class="p3"><strong>2.</strong> <strong>Registro y Uso de la Plataforma: </strong>El Profesional declara que toda la informaci&oacute;n proporcionada a la Plataforma es veraz, completa y actualizada. Asimismo, se compromete a mantener la confidencialidad de sus credenciales de acceso y a notificar de inmediato cualquier uso no autorizado de su cuenta. FIXER no realiza verificaci&oacute;n de antecedentes, matr&iacute;culas o licencias de los Profesionales registrados en la Plataforma. FIXER se reserva el derecho de suspender o cancelar la cuenta de cualquier Profesional en caso de incumplimiento de los presentes T&eacute;rminos o de cualquier conducta que, a juicio de FIXER, pueda ser perjudicial para la Plataforma o para terceros.</p>
            <p class="p3"><strong>3. Limitaci&oacute;n de Responsabilidad</strong>: FIXER no ser&aacute; responsable, bajo ninguna circunstancia, por da&ntilde;os, p&eacute;rdidas, reclamos o gastos derivados de relaciones contractuales o extracontractuales entre Profesionales y Usuarios. Los Profesionales exoneran expresamente a FIXER de cualquier responsabilidad derivada de la prestaci&oacute;n de servicios a los Usuarios, incluyendo, sin limitaci&oacute;n, reclamaciones por da&ntilde;os y perjuicios, lucro cesante, da&ntilde;o emergente o p&eacute;rdida de oportunidades.</p>
            <p class="p3"><strong>4. Indemnidad</strong>: Los Profesionales se comprometen a indemnizar y mantener indemne a FIXER, sus administradores, empleados, agentes y representantes frente a cualquier reclamaci&oacute;n, demanda, da&ntilde;o o perjuicio, incluyendo los honorarios de los abogados, que pudieran surgir del incumplimiento de los presentes T&eacute;rminos o de la prestaci&oacute;n de servicios a los Usuarios.</p>
            <p class="p3"><strong>5. Autorizaci&oacute;n para el Uso de Datos e Imagen</strong>: Los Profesionales autorizan a FIXER a utilizar, reproducir, modificar, distribuir y exhibir p&uacute;blicamente sus nombres, im&aacute;genes, informaci&oacute;n profesional y cualquier otro contenido proporcionado a la Plataforma, con el fin de promocionar sus servicios dentro y fuera de la misma. Esta autorizaci&oacute;n incluye el uso en redes sociales, sitios web y materiales publicitarios. Los Profesionales podr&aacute;n revocar esta autorizaci&oacute;n en cualquier momento, mediante notificaci&oacute;n por escrito a FIXER, sin que dicha revocaci&oacute;n tenga efecto retroactivo.</p>
            <p class="p3"><strong>6. Protecci&oacute;n de Datos Personales</strong>: FIXER tratar&aacute; los datos personales de los Profesionales conforme a lo dispuesto en la Ley de Protecci&oacute;n de Datos Personales 25.326 y su normativa complementaria. Los Profesionales tienen derecho a acceder, rectificar y suprimir sus datos personales, as&iacute; como a ejercer los dem&aacute;s derechos contemplados en dicha ley, dirigi&eacute;ndose a FIXER a trav&eacute;s de los canales establecidos para tal fin.</p>
            <p class="p3"><strong>7. Propiedad Intelectual</strong>: Todos los derechos de propiedad intelectual sobre la Plataforma, su dise&ntilde;o, software, logotipos, marcas, nombres comerciales, y cualquier otro elemento distintivo, son de titularidad exclusiva de FIXER. Los Profesionales no adquieren ning&uacute;n derecho sobre dichos elementos por el uso de la Plataforma, y se comprometen a no utilizarlos de manera contraria a los T&eacute;rminos.</p>
            <p class="p3"><strong>8. Modificaciones</strong>: FIXER se reserva el derecho de modificar estos T&eacute;rminos en cualquier momento. Las modificaciones ser&aacute;n notificadas a los Profesionales mediante su publicaci&oacute;n en la Plataforma o por cualquier otro medio que FIXER considere apropiado. El uso continuado de la Plataforma tras la notificaci&oacute;n de las modificaciones implicar&aacute; la aceptaci&oacute;n de las mismas.</p>
            <p class="p3"><strong>9. Ley Aplicable y Jurisdicci&oacute;n</strong>: Estos T&eacute;rminos se regir&aacute;n e interpretar&aacute;n de acuerdo con las leyes de la Rep&uacute;blica Argentina. Cualquier controversia derivada de la aplicaci&oacute;n o interpretaci&oacute;n de estos T&eacute;rminos ser&aacute; sometida a la jurisdicci&oacute;n exclusiva de los tribunales ordinarios con competencia en la ciudad de Villa Mar&iacute;a, Provincia de C&oacute;rdoba, renunciando los Profesionales a cualquier otro fuero que pudiera corresponderles.</p>
            <p class="p3"><strong>10. Disposiciones Generales</strong>: En caso de que alguna de las disposiciones de estos T&eacute;rminos sea declarada nula o inaplicable, las restantes disposiciones permanecer&aacute;n en pleno vigor y efecto. La no exigencia por parte de FIXER del cumplimiento de alguna disposici&oacute;n no constituir&aacute; en ning&uacute;n caso una renuncia al derecho de exigir su cumplimiento en el futuro.</p>
            <p class="p4">Al hacer clic en "<strong>Acepto los T&eacute;rminos y Condiciones</strong>", el Profesional manifiesta que ha le&iacute;do y comprendido estos T&eacute;rminos y acepta quedar legalmente vinculado por los mismos durante el uso de la Plataforma.</p>
            <p class="p5"><span class="s2"><strong>Pol&iacute;tica de Privacidad de FIXER</strong></span></p>
            <p class="p2"><strong>&Uacute;ltima Actualizaci&oacute;n</strong>: 25/09/2024</p>
            <p class="p2">En <strong>FIXER</strong>, nos comprometemos a proteger la privacidad de los profesionales que utilizan nuestra plataforma (en adelante, los &ldquo;Profesionales&rdquo; y la "Plataforma"). Esta <strong>Pol&iacute;tica de Privacidad</strong> describe c&oacute;mo recopilamos, utilizamos, almacenamos y protegemos los datos personales de los Profesionales, as&iacute; como los derechos que les asisten en relaci&oacute;n con sus datos.</p>
            <p class="p6"><strong>1. Datos Personales que Recopilamos</strong></p>
            <p class="p3">Cuando los Profesionales se registran en nuestra Plataforma, podemos recopilar datos que incluyen, pero no se limitan a:</p>
            <ul class="ul1">
                <li class="li7"><span class="s2"><strong>Informaci&oacute;n de Contacto:</strong>&nbsp;Nombre, apellido, direcci&oacute;n de correo electr&oacute;nico, n&uacute;mero de tel&eacute;fono.</span></li>
                <li class="li7"><span class="s2"><strong>Informaci&oacute;n Profesional:</strong>&nbsp;Profesi&oacute;n u oficio, descripci&oacute;n de los servicios ofrecidos, ubicaci&oacute;n geogr&aacute;fica donde el Profesional presta sus servicios e im&aacute;genes de perfil y de los servicios ofrecidos.</span></li>
                <li class="li7"><span class="s2"><strong>Informaci&oacute;n de Acceso:</strong>&nbsp;Credenciales de acceso, como nombre de usuario y contrase&ntilde;a.</span></li>
                <li class="li7"><span class="s2"><strong>Datos T&eacute;cnicos:</strong>&nbsp;Direcci&oacute;n IP y otros datos t&eacute;cnicos relacionados con el acceso y uso de la Plataforma.</span></li>
            </ul>
            <p class="p6"><strong>2. Finalidades del Tratamiento de Datos</strong></p>
            <p class="p3">Los datos personales recogidos ser&aacute;n utilizados para las siguientes finalidades:</p>
            <ul class="ul1">
                <li class="li7"><span class="s2"><strong>Provisi&oacute;n de Servicios:</strong>&nbsp;Facilitar el contacto entre Profesionales y Usuarios y permitir la promoci&oacute;n de los servicios de los Profesionales en la Plataforma.</span></li>
                <li class="li7"><span class="s2"><strong>Mejora de la Plataforma:</strong>&nbsp;Analizar el uso de la Plataforma para mejorar su funcionalidad, seguridad y experiencia de usuario.</span></li>
                <li class="li7"><span class="s2"><strong>Cumplimiento Legal:</strong>&nbsp;Cumplir con obligaciones legales y reguladoras aplicables a FIXER.</span></li>
                <li class="li8"><span class="s2"><strong>Comunicaci&oacute;n:</strong>&nbsp;Enviar notificaciones sobre actualizaciones de la Plataforma, cambios en los T&eacute;rminos y Condiciones, y otras comunicaciones relevantes para los Profesionales.</span></li>
            </ul>
            <p class="p6"><strong>3. Protecci&oacute;n de Datos</strong></p>
            <p class="p3">FIXER adopta medidas t&eacute;cnicas y organizativas apropiadas para proteger los datos personales de los Profesionales contra acceso no autorizado, alteraci&oacute;n, divulgaci&oacute;n o destrucci&oacute;n. Esto incluye:</p>
            <ul class="ul1">
                <li class="li7"><span class="s2"><strong>Encriptaci&oacute;n:</strong>&nbsp;Los datos sensibles son encriptados durante su transmisi&oacute;n a trav&eacute;s de la Plataforma.</span></li>
                <li class="li7"><span class="s2"><strong>Autenticaci&oacute;n Fuerte:</strong>&nbsp;Uso de m&eacute;todos de autenticaci&oacute;n robusta para acceder a la Plataforma.</span></li>
                <li class="li8"><span class="s2"><strong>Confidencialidad del Personal:</strong>&nbsp;Capacitaci&oacute;n de nuestro personal en las mejores pr&aacute;cticas de seguridad de la informaci&oacute;n.</span></li>
            </ul>
            <p class="p2"><strong>4. Derechos de los Profesionales (ARCO)</strong></p>
            <p class="p3">Los Profesionales tienen los siguientes derechos respecto a sus datos personales:</p>
            <ul class="ul1">
                <li class="li7"><span class="s2"><strong>Acceso:</strong>&nbsp;Los Profesionales pueden solicitar acceso a sus datos personales almacenados en la Plataforma.</span></li>
                <li class="li7"><span class="s2"><strong>Rectificaci&oacute;n:</strong>&nbsp;Si los datos personales son inexactos o incompletos, los Profesionales pueden solicitar su correcci&oacute;n.</span></li>
                <li class="li7"><span class="s2"><strong>Cancelaci&oacute;n:</strong>&nbsp;Los Profesionales pueden solicitar la eliminaci&oacute;n de sus datos personales cuando ya no sean necesarios para las finalidades para las que fueron recopilados.</span></li>
                <li class="li8"><span class="s2"><strong>Oposici&oacute;n:</strong>&nbsp;Los Profesionales pueden oponerse al tratamiento de sus datos personales para ciertos fines.</span></li>
            </ul>
            <p class="p2">Para ejercer estos derechos, los Profesionales deben enviar una solicitud por escrito a <a href="mailto:fixersolucion@gmail.com"><span class="s4">fixersolucion@gmail.com</span></a> con la descripci&oacute;n de su petici&oacute;n.</p>
            <p class="p2"><strong>5. Conservaci&oacute;n de los Datos</strong></p>
            <p class="p2">Los datos personales de los Profesionales ser&aacute;n conservados durante el tiempo necesario para cumplir con las finalidades para las cuales fueron recogidos, o durante el plazo exigido por las leyes aplicables. Una vez cumplidas estas finalidades, los datos ser&aacute;n eliminados de forma segura.</p>
            <p class="p6"><strong>6. Compartici&oacute;n de Datos</strong></p>
            <p class="p3">FIXER no vende, alquila ni intercambia los datos personales de los Profesionales con terceros. Sin embargo, podemos compartir datos personales con:</p>
            <ul class="ul1">
                <li class="li7"><span class="s2"><strong>Proveedores de Servicios:</strong>&nbsp;Empresas que proporcionan servicios de soporte, hosting, mantenimiento, y otros servicios necesarios para la operaci&oacute;n de la Plataforma, bajo estrictas condiciones de confidencialidad.</span></li>
                <li class="li8"><span class="s2"><strong>Autoridades:</strong>&nbsp;Cuando sea requerido por ley, FIXER podr&aacute; compartir datos personales con autoridades competentes.</span></li>
            </ul>
            <p class="p2"><strong>7. Transferencias Internacionales</strong></p>
            <p class="p2">Los datos personales de los Profesionales pueden ser transferidos y almacenados fuera del pa&iacute;s en servidores que se encuentren en jurisdicciones que ofrezcan un nivel de protecci&oacute;n de datos adecuado, conforme a las regulaciones aplicables.</p>
            <p class="p2"><strong>8. Cambios en la Pol&iacute;tica de Privacidad</strong></p>
            <p class="p2">FIXER se reserva el derecho de modificar esta Pol&iacute;tica de Privacidad en cualquier momento. Cualquier cambio ser&aacute; notificado a los Profesionales mediante su publicaci&oacute;n en la Plataforma o por otros medios de comunicaci&oacute;n disponibles. El uso continuado de la Plataforma tras la notificaci&oacute;n de los cambios implicar&aacute; la aceptaci&oacute;n de los mismos.</p>
            <p class="p2"><strong>9. Contacto</strong></p>
            <p class="p4">Para cualquier consulta o duda relacionada con esta Pol&iacute;tica de Privacidad, los Profesionales pueden ponerse en contacto con nosotros en <a href="mailto:fixersolucion@gmail.com"><span class="s4">fixersolucion@gmail.com</span></a>.</p>
        </Box>
    )
}