import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import useFetch, { host } from "../../utils/Fetch";
import { useNavigate } from "react-router-dom";

export default function VerificarEmail() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    const [loading, setLoading] = useState(false);

    const { getFetch } = useFetch();

    const navigate = useNavigate();

    useEffect(() => {
        if (!token) navigate("/");

        getFetch(`${host}/auth/verificar-email/${token}`)
            .then((res) => {
                alert(res.mensaje);
                navigate("/");
            })
            .catch((err) => {
                alert(err.error);
                navigate("/");

            }
            ).finally(() => {
                setLoading(false);
            })
    }, [token])


    return (
        <Box sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            {loading &&
                <CircularProgress
                    size={60}
                    sx={{
                        color: "#38B6FF"
                    }} />
            }
        </Box>
    );
}   