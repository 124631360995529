import { useEffect, useState } from "react";
import { Card, Grid, Modal, Typography, Box, CircularProgress, IconButton, useMediaQuery } from "@mui/material";
import DefaultModal from "../../components/materialMUI/DefaultModal";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShareIcon from '@mui/icons-material/IosShare'
import useFetch, { host } from "../../utils/Fetch";
import { useLocation } from "react-router-dom";
import Perfil from "../../components/Perfil";

export default function Expertos(props) {
    const { categoria, localidad } = props;

    const [perfiles, setPerfiles] = useState([]);
    const [perfil, setPerfil] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const isMobile = useMediaQuery('(max-width:600px)');

    const { postFetch } = useFetch();
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const categoriaId = searchParams.get("categoriaId");
    // const localidadId = searchParams.get("localidadId");
    // const perfilId = searchParams.get("perfilId")

    useEffect(() => {
        setPerfiles([]);
        setLoading(true);
        // if (categoriaId && localidadId) {
        postFetch(`${host}/api/expertos/`, { profesion: categoria.id, localidad: localidad.id }).then(data => {
            setPerfiles(data.perfiles);

            // if (perfilId) {
            //     setPerfil(data.perfiles.find(p => p.id === parseInt(perfilId)))
            // }
        })
            .finally(() => setLoading(false))
        // }
    }, [categoria, localidad])

    function handleGetShortDescription(description) {
        let longitud = 80
        if (description.length > longitud) {
            return description.slice(0, longitud) + "...";
        }
        return description;
    }

    if (loading) {
        return (
            <Grid container
                sx={{
                    height: isMobile ?
                        "calc(100% - 350px)"
                        :
                        "calc(100% - 200px)",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CircularProgress sx={{
                    color: "#8DD5FF",

                }}
                    size={80} />
            </Grid>
        )
    }

    return (
        <Grid container sx={{
            // height: "100%",
            gap: "20px",
            paddingX: "50px",
            paddingY: "50px",
        }}>
            <Grid container item
                xs={12}
                justifyContent={"space-evenly"}
                gap={"50px"}
            >
                {perfiles.map((p, index) => (
                    <Box
                        onClick={() => {
                            setPerfil(p);
                            setModalOpen(true);
                        }}
                        variant="filled"
                        key={index}
                        sx={{
                            width: "350px",
                            height: "550px",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            display: "flex",
                            cursor: "pointer",
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                            borderRadius: "20px",
                            overflow: "hidden",

                        }}
                    >

                        <img src={host + p.foto_perfil} alt="Foto" style={{
                            width: "100%",
                            height: "350px",
                            objectFit: "cover",
                            borderRadius: "20px 20px 0px 0px",
                        }} />
                        <Grid
                            container
                            item
                            sx={{
                                flexDirection: "column",
                                gap: 1,
                                paddingX: "30px",
                                paddingBottom: 2,
                                paddingTop: 2,
                                flexGrow: 1,
                                justifyContent: "space-between",
                                paddingBottom: "25px",
                                alignItems: "flex-end"
                            }}>
                            <Grid item container sx={{
                                flexDirection: "column",
                                alignItems: "flex-start",
                            }}>
                                <Typography variant="h6">
                                    {p.nombre} {p.apellido}
                                </Typography>
                                <Typography variant="body1">
                                    {handleGetShortDescription(p.descripcion)}
                                </Typography>
                            </Grid>
                            <Box
                                sx={{
                                    borderRadius: "50px",
                                    border: "1px solid #E0E0E0",
                                    paddingX: "20px",
                                    paddingY: "5px",
                                    display: "flex",
                                    width: "fit-content",
                                }}
                            >
                                <Typography variant="h6">
                                    Ver Más
                                </Typography>
                            </Box>
                        </Grid>
                    </Box>
                ))}
            </Grid>
            <DefaultModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                topComponent={
                    <Typography sx={{
                        color: "#666",
                        fontSize: "20px",
                        fontWeight: "bold",
                    }}>
                        {categoria.nombre}
                    </Typography>
                }
            >
                <Perfil perfil={perfil} />
            </DefaultModal>
        </Grid >
    )
}