import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Dashboard from "./dashboards/Dashboard";
import { useEffect, useState } from 'react';
import { UserProvider } from "./contexts/UserContext";
import ImagePicker from './components/ImagePicker';
import { Box } from '@mui/material';

export default function App() {
  const [puntos, setPuntos] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPuntos(puntos => puntos < 3 ? puntos + 1 : 0);
    }, 700);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path={'/*'} element={
            <Dashboard />
          } />
        </Routes>
      </UserProvider>
    </Router>
  )
}