import { Box, IconButton, Slide, Typography, useMediaQuery } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SideComponent from './SideComponent';
import BotomComponent from './BotomComponent';

import { useRef, useEffect, useState } from 'react';

import logo from '../assets/partials/logo.png';

export default function BaseLayout() {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [posicion, setPosicion] = useState(0);

    const mainRef = useRef();
    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            setPosicion(mainRef.current.scrollTop);
            console.log(mainRef.current.scrollTop);
        };

        const mainElement = mainRef.current;
        mainElement.addEventListener('scroll', handleScroll);

        return () => {
            mainElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box sx={{
            height: '100vh',
            width: '100vw',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
        }}>
            {!isMobile &&
                <Box sx={{
                    width: '20%',
                    height: '100%',
                }}>
                    <SideComponent />
                </Box>
            }
            <Box
                ref={mainRef}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    width: { xs: '100%', md: '80%' },
                    height: { xs: `${window.innerHeight - 70}px`, md: '100vh' },
                    overflowY: "auto",
                    overflowX: "hidden",
                }}>
                {isMobile &&
                    <Box sx={{
                        height: "70px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <img src={logo} alt="logo" style={{
                            width: '100px',
                            padding: '20px',
                            objectFit: 'contain',
                        }} />
                    </Box>
                }
                <Box sx={{
                    height: isMobile ? "calc(100vh - 80px)" : "100vh",
                    zIndex: 0,
                    position: "relative",
                }}>
                    <Outlet />
                    <Slide
                        direction="up"
                        in={
                            (location.pathname === "/" || location.pathname === "/publicaciones") &&
                            posicion > 100
                        }
                        mountOnEnter
                        unmountOnExit
                        timeout={600}
                    >
                        <Box sx={{
                            width: "300px",
                            height: "50px",
                            position: "fixed",
                            borderRadius: 10,
                            boxShadow: "0px 5px 5px 0px #aaa",
                            bottom: isMobile ? "90px" : "30px",
                            left: isMobile ? "calc(50% - 150px)" : "calc(60% - 150px)",
                            backgroundColor: "#8DD5FF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                            onClick={() => navigate("/signup")}>
                            <Typography sx={{
                                fontSize: "16px",
                                color: "white",
                            }}>
                                ¡Formá parte de Fixer!
                            </Typography>
                        </Box>
                    </Slide>
                </Box>
            </Box>
            {isMobile && <Box sx={{
                width: '100%',
                height: '70px',
            }}>
                <BotomComponent />
            </Box>
            }
        </Box>
    )
}