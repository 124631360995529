import { Box } from "@mui/material";
import TerminosProfesionales from "./TerminosProfesionales";
import TerminosUsuarios from "./TerminosUsuarios";

export default function TerminosYCondiciones() {
    return (
        <Box sx={{
            padding: "40px"
        }}>
            <TerminosProfesionales />
            <TerminosUsuarios />
        </Box>
    )
}