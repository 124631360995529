import { Routes, Route } from "react-router-dom";
import BaseLayout from "../layouts/BaseLayout";
import HomePage from "../pages/homePage/HomePage";
// import CategoriasPage from "../pages/categoriaPage/CategoriaPage";
import Signup from "../pages/signup/Signup";
import Home from "../pages/home/Home";
import VentanillaEmpleo from "../pages/ventanillaEmpleo/VentanillaEmpleo";
import Login from "../pages/login/Login";
import PrivateDashboard from "./PrivateDashboard";
import PrivateRoutes from "./PrivateRoutes";
import Publicaciones from "../pages/publicaciones/Publicaciones";
import VerificarEmail from "../pages/verificarEmail/VerificarEmail";
import Perfil from "../pages/perfil/Perfil";
import TerminosYCondiciones from "../pages/terminosYCondiciones/TerminosYCondiciones";

const publicRoutes = [
    {
        url: "/",
        compontent: <HomePage />
    },
    // {
    //     url: "/perfiles",
    //     compontent: <CategoriasPage />
    // },
    {
        url: "/signup",
        compontent: <Signup />
    },
    {
        url: "/login",
        compontent: <Login />
    },
    {
        url: "/publicaciones",
        compontent: <Publicaciones />
    },
    {
        url: "/verificar-email",
        compontent: <VerificarEmail />
    },
    {
        url: "/terminos-y-condiciones",
        compontent: <TerminosYCondiciones />
    }
    // {
    //     nombre: "Plan de cuentas",
    //     seccion: "plan-cuentas",
    //     url: "/plan-cuentas/",
    //     compontent: <PlanCuentasPage />
    // },
]

const privateRoutes = [
    {
        url: "/perfil",
        compontent: <Perfil />
    },
]

export default function AdminDashboard() {
    return (
        <Routes>
            <Route path="/home/" element={<Home />} />
            <Route element={<BaseLayout />}>
                {publicRoutes.map((pagina, index) => (
                    <Route key={index} path={pagina.url} element={pagina.compontent} />
                ))}
                <Route element={<PrivateRoutes />} >
                    {privateRoutes.map((pagina, index) => (
                        <Route key={index} path={pagina.url} element={pagina.compontent} />
                    ))}
                </Route>
                <Route path="*" element={<h1>404</h1>} />
            </Route>
        </Routes>
    )
}