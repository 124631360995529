import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";

export const host = "https://api.fixersoluciones.com";

function useCustomFetch() {
    const navigate = useNavigate();
    const { getRefreshToken, handleSetAccessToken, handleSetRefreshToken } = useContext(UserContext);

    const customFetch = async (url, requestInit) => {
        try {
            const response = await fetch(url, requestInit);
            const responseText = await response.text();
            let responseData;

            try {
                responseData = JSON.parse(responseText);
            } catch (error) {
                throw new Error("Error parsing response to JSON");
            }

            if (!response.ok) {
                if (response.status === 401) {
                    const refreshResponse = await fetch(`${host}/auth/token/refresh/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ refresh: getRefreshToken() })
                    });

                    if (refreshResponse.ok) {
                        const data = await refreshResponse.json();
                        handleSetAccessToken(data.access);
                        handleSetRefreshToken(data.refresh);

                        requestInit.headers['Authorization'] = `Token ${data.access}`;

                        return customFetch(url, requestInit);
                    } else {
                        navigate('/login');
                        return; // Salir de la función para evitar hacer la petición de nuevo
                    }
                }
                throw responseData;
            }

            return responseData; // Devuelve el objeto JSON si todo está bien
        } catch (error) {
            throw error; // Lanza el error tal como se recibió, que ahora puede ser un objeto JSON
        }
    };

    return customFetch;
}

export default function useFetch() {
    const { getAccessToken } = useContext(UserContext);
    const customFetch = useCustomFetch();

    function getHeaderToken(header = {}) {
        return { ...header, 'Authorization': `Token ${getAccessToken()}` };
    }

    async function getFetch(url, loginRequired = false) {
        let headers = {};

        if (loginRequired) {
            headers = getHeaderToken(headers);
        }

        return customFetch(url, { method: 'GET', headers: headers });
    }

    async function postFetch(url, body, loginRequired = false) {
        let headers = { 'Content-Type': 'application/json' };

        if (loginRequired) {
            headers = getHeaderToken(headers);
        }

        return customFetch(url, { method: 'POST', headers: headers, body: JSON.stringify(body) });
    }

    async function postFetchFormData(url, body, loginRequired = false) {
        let headers = {};

        if (loginRequired) {
            headers = getHeaderToken(headers);
        }

        return customFetch(url, { method: 'POST', headers: headers, body: body });
    }

    async function putFetchFormData(url, body, loginRequired = false) {
        let headers = {};

        if (loginRequired) {
            headers = getHeaderToken(headers);
        }

        return customFetch(url, { method: 'PUT', headers: headers, body: body });
    }

    async function putFetch(url, body, loginRequired = false) {
        let headers = { 'Content-Type': 'application/json' };

        if (loginRequired) {
            headers = getHeaderToken(headers);
        }

        return customFetch(url, { method: 'PUT', headers: headers, body: JSON.stringify(body) });
    }

    async function deleteFetch(url, body, loginRequired = false) {
        let headers = { 'Content-Type': 'application/json' };

        if (loginRequired) {
            headers = getHeaderToken(headers);
        }

        return customFetch(url, { method: 'DELETE', headers: headers, body: JSON.stringify(body) });
    }

    return { getFetch, postFetch, putFetch, deleteFetch, postFetchFormData, putFetchFormData };
}