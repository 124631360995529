import { Box } from "@mui/material";

export default function TerminosUsuarios() {
    return (
        <Box>
            <p class="p1"><span class="s1"><strong><br /> </strong></span><strong>T&eacute;rminos y Condiciones de Uso para Usuarios en FIXER</strong></p>
            <p class="p2"><strong>&Uacute;ltima Actualizaci&oacute;n</strong>: 25/09/2024</p>
            <p class="p3">Estos <strong>T&eacute;rminos y Condiciones</strong> (en adelante, los "T&eacute;rminos") regulan el acceso y uso de la plataforma <strong>FIXER</strong> (en adelante, la "Plataforma") por parte de las personas que desean contratar los servicios ofrecidos a trav&eacute;s de la misma (en adelante, el &ldquo;Usuario&rdquo; o los &ldquo;Usuarios&rdquo;). Al acceder y utilizar la Plataforma, el Usuario acepta plenamente estos T&eacute;rminos.</p>
            <p class="p3"><strong>1. Naturaleza del Servicio</strong>: FIXER es una plataforma digital que facilita el contacto entre Profesionales de diversos oficios y profesiones (en adelante, &ldquo;los Profesionales&rdquo;) y los Usuarios interesados en contratar sus servicios. FIXER no es parte ni participa en las relaciones contractuales que se generan entre los Profesionales y los Usuarios, ni en la ejecuci&oacute;n de los servicios ofrecidos. En consecuencia, FIXER no asume responsabilidad alguna por la calidad, seguridad, idoneidad o cumplimiento de los servicios prestados por los Profesionales.</p>
            <p class="p3"><strong>2.</strong> <strong>Uso de la Plataforma: </strong>el Usuario acepta utilizar la Plataforma exclusivamente con el fin de contactar a Profesionales para la prestaci&oacute;n de servicios. FIXER no realiza verificaci&oacute;n de antecedentes, matr&iacute;culas o licencias de los Profesionales registrados en la Plataforma, por lo que se recomienda a los Usuarios realizar las indagaciones y verificaciones necesarias antes de proceder a la contrataci&oacute;n. FIXER se reserva el derecho de suspender o limitar el acceso a cualquier Usuario que haga un uso indebido de la Plataforma.</p>
            <p class="p3"><strong>3. Limitaci&oacute;n de Responsabilidad</strong>: FIXER no ser&aacute; responsable, bajo ninguna circunstancia, por da&ntilde;os, p&eacute;rdidas, reclamos o gastos derivados de relaciones contractuales o extracontractuales entre Profesionales y Usuarios. Los Usuarios exoneran expresamente a FIXER de cualquier responsabilidad derivada de la prestaci&oacute;n de servicios a los Usuarios, incluyendo, sin limitaci&oacute;n, reclamaciones por da&ntilde;os y perjuicios, lucro cesante, da&ntilde;o emergente o p&eacute;rdida de oportunidades.</p>
            <p class="p3"><strong>5. Contenido de la Plataforma</strong>: FIXER puede exhibir informaci&oacute;n proporcionada por los Profesionales, tales como nombres, im&aacute;genes y descripciones de servicios, as&iacute; como valoraciones de otros Usuarios y cualquier otro contenido relevante para la contrataci&oacute;n de los servicios. FIXER no garantiza la veracidad, exactitud o actualidad de dicha informaci&oacute;n, ya que es responsabilidad exclusiva de los Profesionales que la proporcionan. El Usuario reconoce que deber&aacute; realizar las averiguaciones necesarias sobre los Profesionales antes de proceder a la contrataci&oacute;n, eximiendo a FIXER de cualquier responsabilidad por errores, omisiones o inexactitudes en la informaci&oacute;n exhibida.</p>
            <p class="p3"><strong>7. Modificaciones</strong>: FIXER se reserva el derecho de modificar estos T&eacute;rminos en cualquier momento. Las modificaciones ser&aacute;n notificadas a los Usuarios mediante su publicaci&oacute;n en la Plataforma o por cualquier otro medio que FIXER considere apropiado. El uso continuado de la Plataforma tras la notificaci&oacute;n de las modificaciones implicar&aacute; la aceptaci&oacute;n de las mismas.</p>
            <p class="p3"><strong>8. Ley Aplicable y Jurisdicci&oacute;n</strong>: Estos T&eacute;rminos se regir&aacute;n e interpretar&aacute;n de acuerdo con las leyes de la Rep&uacute;blica Argentina. Cualquier controversia derivada de la aplicaci&oacute;n o interpretaci&oacute;n de estos T&eacute;rminos ser&aacute; sometida a la jurisdicci&oacute;n exclusiva de los tribunales ordinarios con competencia en la ciudad de Villa Mar&iacute;a, Provincia de C&oacute;rdoba, renunciando los Usuarios a cualquier otro fuero que pudiera corresponderles.</p>
            <p class="p3"><strong>9. Disposiciones Generales</strong>: En caso de que alguna de las disposiciones de estos T&eacute;rminos sea declarada nula o inaplicable, las restantes disposiciones permanecer&aacute;n en pleno vigor y efecto. La no exigencia por parte de FIXER del cumplimiento de alguna disposici&oacute;n no constituir&aacute; en ning&uacute;n caso una renuncia al derecho de exigir su cumplimiento en el futuro.</p>
            <p class="p3">Al acceder, navegar o utilizar la Plataforma, el Usuario acepta de forma plena e incondicionada estos T&eacute;rminos. En caso de no aceptar los presentes T&eacute;rminos, el Usuario deber&aacute; abstenerse de utilizar la Plataforma.</p>
            <p class="p4">&nbsp;</p>
            <p class="p5"><span class="s2"><strong>Pol&iacute;tica de Privacidad de FIXER para los Usuarios</strong></span></p>
            <p class="p2"><strong>&Uacute;ltima Actualizaci&oacute;n</strong>: 25/09/2024</p>
            <p class="p2">En <strong>FIXER</strong>, nos comprometemos a proteger la privacidad tanto de los profesionales (en adelante, los &ldquo;Profesionales&rdquo;) como de los usuarios finales (en adelante, los &ldquo;Usuarios&rdquo;) que utilizan nuestra plataforma (en adelante la &ldquo;Plataforma&rdquo;). Esta secci&oacute;n complementa la Pol&iacute;tica de Privacidad aplicable a los Profesionales y detalla c&oacute;mo recopilamos, utilizamos, almacenamos y protegemos los datos personales de los Usuarios.</p>
            <p class="p6"><strong>1. Datos Personales que Recopilamos</strong></p>
            <p class="p6">Cuando los Usuarios navegan por la Plataforma, podemos recopilar los siguientes datos:</p>
            <ul class="ul1">
                <li class="li7"><span class="s2"><strong>Direcci&oacute;n IP</strong> y otros datos relacionados con la conexi&oacute;n y la navegaci&oacute;n.</span></li>
                <li class="li8"><span class="s2"><strong>Cookies</strong> y tecnolog&iacute;as de seguimiento similares, para mejorar la experiencia de Usuario y optimizar el funcionamiento de la Plataforma.</span></li>
            </ul>
            <p class="p6"><strong>2. Finalidades del Tratamiento de Datos</strong></p>
            <p class="p3">Los datos personales recopilados de los Usuarios ser&aacute;n utilizados para las siguientes finalidades:</p>
            <ul class="ul1">
                <li class="li9"><span class="s2"><strong>Mejora de la Plataforma:</strong>&nbsp;Optimizar el rendimiento de la Plataforma y su funcionalidad.</span></li>
                <li class="li8"><span class="s2"><strong>Cumplimiento Legal:</strong>&nbsp;Cumplir con obligaciones legales y reguladoras aplicables a FIXER.</span></li>
            </ul>
            <p class="p6"><strong>3. Derechos de los Usuarios</strong></p>
            <p class="p2">Los Usuarios tienen derecho a acceder, rectificar y, en su caso, oponerse al tratamiento de los datos recopilados durante la navegaci&oacute;n. Para ejercer estos derechos, pueden ponerse en contacto con el equipo de FIXER a trav&eacute;s del correo electr&oacute;nico: fixersolucion<span class="s5">@gmail.com.</span></p>
            <p class="p2"><strong>4. Conservaci&oacute;n de los Datos</strong></p>
            <p class="p2">Los datos recopilados de los Usuarios ser&aacute;n conservados durante el tiempo necesario para cumplir con las finalidades mencionadas o seg&uacute;n lo exigido por las leyes aplicables.</p>
            <p class="p2"><strong>5. Cambios en la Pol&iacute;tica de Privacidad</strong></p>
            <p class="p2">FIXER se reserva el derecho de modificar esta Pol&iacute;tica de Privacidad en cualquier momento. Cualquier cambio ser&aacute; debidamente notificado a los Usuarios mediante su publicaci&oacute;n en la Plataforma.</p>
            <p class="p2"><strong>6. Contacto</strong></p>
            <p class="p10">Para cualquier consulta o duda relacionada con esta Pol&iacute;tica de Privacidad, los Profesionales pueden ponerse en contacto con nosotros en <a href="mailto:fixersolucion@gmail.com"><span class="s6">fixersolucion@gmail.com</span></a>.</p>
        </Box>
    )
}