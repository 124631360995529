import { Box, Slide, Typography } from "@mui/material";
import { useState, useContext, useRef, useEffect } from "react";
import Categorias from "./Categorias";
import Expertos from "./Expertos";
import { UserContext } from "../../contexts/UserContext";
import Filtros from "./Filtros";
import DefaultModal from "../../components/materialMUI/DefaultModal";
import useFetch, { host } from "../../utils/Fetch";
import Perfil from "../../components/Perfil";
import PerfilById from "../perfil/PerfilById";
import { useNavigate } from "react-router-dom";

export default function HomePage() {
    const {
        categorias,
        categoria,
        setCategoria,
        localidad,
        setActualPage,
    } = useContext(UserContext);

    const [posicion, setPosicion] = useState(0);

    const [modalPerfilOpen, setModalPerfilOpen] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const perfilId = urlParams.get("perfil");

    useEffect(() => {
        setActualPage("inicio");

        if (perfilId) {
            setModalPerfilOpen(true)
        }
    }, [])

    const [error, setError] = useState(false);

    const fiiltrosRef = useRef();

    return (
        <Box
            ref={fiiltrosRef}
            sx={{
                position: "relative",
                width: "100%",
                height: "100%",
            }}>
            <Box
                sx={{
                    justifyContent: "flex-start",
                    width: "100%",
                    height: "100%",
                }}>
                <Filtros setError={setError} error={error} />
                {!categoria || !localidad ?
                    <Categorias categorias={localidad?.profesiones || categorias} onClick={(categoria) => {
                        if (localidad) {
                            setCategoria(categoria)
                        } else {
                            setError(true)

                            if (fiiltrosRef.current) fiiltrosRef.current.scrollIntoView({ behavior: "smooth" })
                        }
                    }} />
                    :
                    <Expertos categoria={categoria} localidad={localidad} />
                }
            </Box >
            <DefaultModal
                open={modalPerfilOpen}
                onClose={() => setModalPerfilOpen(false)}
                topComponent={
                    <Typography sx={{
                        color: "#666",
                        fontSize: "20px",
                        fontWeight: "bold",
                    }}>
                        Volver
                    </Typography>
                }
            >
                <PerfilById id={perfilId} />
            </DefaultModal>
        </Box >
    )
}