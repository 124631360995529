import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';

import ImageIcon from '@mui/icons-material/Image';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ImagePicker(props) {
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0, startX: 0, startY: 0 });
    const imageRef = useRef(null);
    const fileInputRef = useRef(null);

    const MAX_FILE_SIZE_MB = 20;

    const [image, setImage] = useState(null);

    const { onSubmit, size = 600, background1 = "#333", background2 = "#222", color1 = "white", color2 = "white", color3 = "#555", borderRadius = "0px" } = props;

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file?.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            alert(`La imagen debe ser menor a ${MAX_FILE_SIZE_MB} MB`);
            setImage(null);
        } else {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleOnSubmit = () => {
        const img = document.createElement("img");
        img.src = image;

        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        const originalWidth = img.width;
        const originalHeight = img.height;

        // Establece el tamaño del canvas en size
        canvas.width = size;
        canvas.height = size;

        // Rellena el fondo del canvas con negro
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, size, size);

        // Calcula las nuevas dimensiones para mantener la proporción de aspecto
        let newWidth, newHeight;

        newWidth = size;
        newHeight = (originalHeight * size) / originalWidth;


        // Dibuja la imagen en el canvas usando position para el desplazamiento
        ctx.drawImage(img, 0, 0, originalWidth, originalHeight, position.left, position.top, newWidth, newHeight)

        canvas.toBlob((blob) => {
            let newFile = new File([blob], "image.jpeg", { type: "image/jpeg" });
            const imageURL = URL.createObjectURL(newFile);
            onSubmit({
                file: newFile,
                url: imageURL,
            });
        }, "image/jpeg");

        setImage(null);
        setPosition({ top: 0, left: 0 });
    };

    const handleDragStart = (e) => {
        if (!image) return;
        const event = e.type === 'touchstart' ? e.touches[0] : e;
        setIsDragging(true);
        setPosition({ ...position, startX: event.clientX, startY: event.clientY });
    };

    const handleDrag = (e) => {
        if (!image || !isDragging) return;
        const event = e.type === 'touchmove' ? e.touches[0] : e;
        const scaleFactor = e.type === 'touchmove' ? 0.1 : 1; // Reduce sensitivity for touch events
        const deltaX = (event.clientX - position.startX) * scaleFactor;
        const deltaY = (event.clientY - position.startY) * scaleFactor;
        setPosition((prevPosition) => ({
            ...prevPosition,
            top: prevPosition.top + deltaY,
            left: prevPosition.left + deltaX,
            startX: event.clientX,
            startY: event.clientY,
        }));
    };

    const handleDragEnd = () => {
        if (!image) return;
        let newPosition = { top: position.top, left: position.left };

        if (position.left > 0) {
            newPosition = { ...newPosition, left: 0 };
        }

        if (position.left <= -imageRef.current.width + size) {
            newPosition = { ...newPosition, left: -imageRef.current.width + size };
        }

        setPosition(newPosition);
        setIsDragging(false);
    };

    useEffect(() => {
        const handleTouchMove = (e) => {
            if (isDragging) {
                handleDrag(e);
            }
        };

        document.addEventListener('touchmove', handleTouchMove);
        return () => {
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, [isDragging]);

    const headerHeight = 50;

    return (
        <Box sx={{
            width: `${size}px`,
            height: `${size + headerHeight}px`,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            borderRadius: borderRadius,
        }}>
            <Box sx={{
                width: "100%",
                height: `${headerHeight}px`,
                borderBottom: `1px solid ${color3}`,
                display: "flex",
                alignItems: "center",
                justifyContent: image ? "space-between" : "center",
                backgroundColor: background1,
                color: color1,
                padding: "0 10px",
                boxSizing: "border-box",

            }}>
                {image && (
                    <IconButton
                        onClick={() => {
                            setImage(null)
                            setPosition({ top: 0, left: 0 });
                        }}
                        sx={{
                            color: color1,
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                )}
                <Typography variant="h6" sx={{ textAlign: 'center' }}>{image ? "Arrastra" : "Selecciona una imagen"}</Typography>
                {
                    image && (
                        <Button
                            onClick={() => {
                                handleOnSubmit();
                            }}
                            sx={{
                                color: isDragging ? color3 : color1,
                            }}
                        >
                            Guardar
                        </Button>
                    )
                }
            </Box>
            {!image ? (
                <Box sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    backgroundColor: background2,
                    flexDirection: 'column',
                }}
                    onClick={() => fileInputRef.current.click()}
                >
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                    />
                    <ImageIcon sx={{ fontSize: 100, color: color2 }} />
                    <Typography sx={{
                        color: color2,
                        fontSize: 16,

                    }}>Haz click para seleccionar una imagen</Typography>
                </Box>
            ) : (
                <Box
                    onMouseDown={handleDragStart}
                    onMouseMove={handleDrag}
                    onMouseUp={handleDragEnd}
                    onMouseLeave={handleDragEnd}
                    onTouchStart={handleDragStart}
                    onTouchMove={handleDrag}
                    onTouchEnd={handleDragEnd}
                    onTouchCancel={handleDragEnd}
                    sx={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        userSelect: 'none',
                        cursor: isDragging ? 'grabbing' : 'grab',
                        position: 'relative',
                        backgroundColor: background2,
                    }}
                >
                    <Grid sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                        boxSizing: 'border-box',
                        backgroundImage: isDragging && `linear-gradient(${color3} 1px, transparent 1px), linear-gradient(90deg, ${color3} 1px, transparent 1px)`,
                        backgroundSize: 'calc(100% / 3) calc(100% / 3)',
                    }} />
                    {image && (
                        <img
                            ref={imageRef}
                            src={image}
                            alt="Selected"
                            style={{
                                position: 'absolute',
                                width: '100%',
                                top: position.top,
                                left: position.left,

                                transition: isDragging ? 'none' : 'all 0.5s',
                            }}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
}