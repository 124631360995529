import {
    Box,
    Drawer,
    List,
    ListItem,
    Typography,
    IconButton,
    ListItemIcon,
    Modal
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

import logo from "../assets/partials/logo.png";

import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import LoginIcon from '@mui/icons-material/Login';

import BuildIcon from '@mui/icons-material/Build';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';

import WorkIcon from '@mui/icons-material/Work';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import LogoutIcon from '@mui/icons-material/Logout';
import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import CrearPublicacion from "../components/CrearPublicacion";

export default function SideComponent() {
    const [modalFixerJobs, setModalFixerJobs] = useState(false);

    const [openCrearPublicacion, setOpenCrearPublicacion] = useState(false);

    const { logout, accessToken, actualPage, setLocalidad, setCategoria } = useContext(UserContext)
    const iconSelectedStyle = {
        width: "30px",
        height: "30px",
        marginRight: "10px",
        color: "#38B6FF",
    }

    const iconDefaultStyle = {
        width: "30px",
        height: "30px",
        marginRight: "10px",
    }

    const paginas = [
        {
            nombre: "Inicio",
            onClick: (index) => {
                if (actualPage === "inicio") {
                    setCategoria(null);
                    setLocalidad(null);
                    return;
                }
                navigate("/")
            },
            selectIcon: <HomeIcon sx={iconSelectedStyle} />,
            defaultIcon: <HomeOutlinedIcon sx={iconDefaultStyle} />,
            value: "inicio",
        },
        {
            nombre: "Publicaciones",
            onClick: (index) => {
                navigate("/publicaciones")
            },
            selectIcon: <BuildIcon sx={iconSelectedStyle} />,
            defaultIcon: <BuildOutlinedIcon sx={iconDefaultStyle} />,
            value: "publicaciones",
        },
        {
            nombre: "Perfil",
            onClick: (index) => {
                navigate("/perfil")
            },
            selectIcon: <AccountCircleIcon sx={iconSelectedStyle} />,
            defaultIcon: <AccountCircleOutlinedIcon sx={iconDefaultStyle} />,
            notShow: !accessToken,
            value: "perfil"

        },
        {
            nombre: "Crear",
            onClick: () => setOpenCrearPublicacion(true),
            selectIcon: <AddCircleOutlineOutlined sx={iconSelectedStyle} />,
            defaultIcon: <AddCircleOutlineOutlined sx={iconDefaultStyle} />,
        },
        {
            nombre: "Cerrar Sesión",
            onClick: logout,
            selectIcon: <LogoutIcon sx={iconSelectedStyle} />,
            defaultIcon: <LogoutIcon sx={iconDefaultStyle} />,
            notShow: !accessToken,
        },
        {
            nombre: "Iniciar Sesión",
            onClick: (index) => {
                navigate("/login")
            },
            selectIcon: <LoginIcon sx={iconSelectedStyle} />,
            defaultIcon: <LoginIcon sx={iconDefaultStyle} />,
            notShow: accessToken,
            value: "login",
        }
    ]

    const navigate = useNavigate();

    return (
        <Box sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            overflow: "hidden",
            boxShadow: 3,
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "80px",
            }}>
                <img src={logo} alt="Logo" style={{
                    width: "80px",
                }} />
            </Box>
            <List sx={{
                paddingTop: "50px",
            }}
            >
                {paginas.map((pagina, index) => {
                    if (pagina.notShow) return null;


                    return (
                        <ListItem sx={{
                            cursor: "pointer",
                            paddingY: "20px",
                            paddingRight: "50px",
                            paddingLeft: "30px",
                            borderRadius: "50px 0px 0px 50px",
                            display: "flex",
                            alignItems: "center",
                        }}
                            key={pagina.nombre}
                            onClick={() => pagina.onClick(index)}
                        >
                            {actualPage === pagina.value ? pagina.selectIcon : pagina.defaultIcon}
                            <Typography sx={{
                                fontWeight: "bold",
                                fontSize: "20px",
                                color: actualPage === pagina.value ? "#38B6FF" : "black",
                            }} >
                                {pagina.nombre}
                            </Typography>
                        </ListItem>
                    )
                })}
            </List>
            <Modal
                open={modalFixerJobs}
                onClose={() => setModalFixerJobs(false)}
                disableAutoFocus
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    p: 4,
                    borderRadius: 4,
                }}>
                    <Typography sx={{
                        fontSize: 20,
                        fontWeight: 'bold',
                    }}>
                        Fixer Jobs
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        En Fixer Jobs podrás encontrar trabajos de todo tipo, o bien, buscar trabajadores para tu empresa de manera rápida y sencilla. Proximamente...
                    </Typography>
                </Box>
            </Modal>
            <CrearPublicacion open={openCrearPublicacion} onClose={() => setOpenCrearPublicacion(false)} />
        </Box>
    )
}